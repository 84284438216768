<template>
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-3">
              <input v-model="name" type="name" class="form-control" placeholder="Name / Nombre">
        </div>  
        <div class="col-md-12 mb-3">
              <input v-model="service" type="name" class="form-control" placeholder="Service / Servicio">
        </div>  
        <div class="col-md-12 mb-3">
              <input v-model="address" type="name" class="form-control" placeholder="Address / Dirección">
        </div>  
        <div class="col-md-6 mb-3">
              <input v-model="phoneNumber" type="text" class="form-control" placeholder="Telephone / Teléfono">
          </div>
          <div class="col-md-6 mb-3">
              <input v-model="website" type="text" class="form-control" placeholder="Website / Sitio web">
          </div>
          <div class="col-md-9 text-left">
              <p class="text-info-label">Do you need a platform to receive reservations / Necesitas una plataforma para recibir reservaciones</p>
          </div>
          <div class="col-md-3">
            <select name="" id="" v-model="isNeedPlatform" class="form-select">
                <option :value='true'>Yes</option>
                <option :value='false'>No</option>
            </select>
          </div>
          <div class="col-md-12 mb-3">
              <input v-model="email" type="email" class="form-control" placeholder="Email">
          </div>
          <div class="col-md-12 mb-3">
              <input v-model="email2" type="email" class="form-control" placeholder="Confirm / Confirmar Email">
          </div>
          <div class="col-md-12 mb-3">
              <input v-model="password" type="password" class="form-control" placeholder="Password / Contraseña">
          </div>
          <div class="col-md-12 mb-3">
              <input v-model="password2" type="password" class="form-control" placeholder="Confirm Password / Confirma Contraseña">
          </div>          
          <div class="col-md-12 mb-3">
              <input v-model="location" type="text" class="form-control" placeholder="Location / Ubicación">
          </div>          
          <div class="col-md-12 mb-3 text-left">
            <label for="" class="title-form">Category / Categoria</label>
            <select  v-if="language== 'ENGLISH'" name="" id="" v-model="category" class="form-select">
                <option selected disabled="disabled">Category ...</option>
                <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{category.name.ENGLISH}}</option>
            </select>
            <select v-else name="" id="" v-model="category" class="form-select">
                <option selected disabled="disabled">Categoria ...</option>
                <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{category.name.SPANISH}}</option>
            </select>
          </div>          
          <div class="col-md-12 mb-3 text-left">
            <label for="" class="title-form">Price Range / Rango de Precios</label>
            <div class="row">
                <div class="col-md-3">
                    <select name="" id="" v-model="currency" class="form-select">
                        <option value="USD" selected>USD</option>
                        <option value="MXN">MXN</option>
                        <option value="EURO">EURO</option>
                    </select>
                </div>
                <div class="col-md-4">
                  <input v-model="minPrice" type="number" class="form-control" placeholder="$">
                </div>
                <div class="col-md-1">
                    <h1 class="subtitle-form">To<br> Hasta</h1>
                </div>
                <div class="col-md-4">
                  <input v-model="maxPrice" type="number" class="form-control" placeholder="$">
                </div>
            </div>
          </div>          
      </div>
      <button :disabled="enviando" @click="crearUsuario" class="btn-send separar">
        <label v-if="!enviando">Send / Enviar</label>
        <span v-if="enviando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <label v-if="enviando">Enviando...</label>
      </button>
    </div>
  </template>
  
  <script>
    import {categoryService} from '@/services/categories.service.js';
    import {customerService} from '@/services/customers.service.js';
    import Swal from 'sweetalert2';
    export default {
        props:['language'],
        name:'RegisterCustomerForm',
        data() {
            return {
                categories:[],
                name:'',
                service:'',
                address:'',
                phoneNumber:'',
                website:'',
                isNeedPlatform: true,
                email:'',
                email2:'',
                password:'',
                password2:'',
                location:'',
                category:'Category ...',
                currency:'USD',
                minPrice:0,
                maxPrice:0,
                enviando:false
            }
        },
        created() {
            console.log("obteniendo categorias y subcategorias")
            categoryService.getAll()
                .then(x => {
                    console.log(x);
                    this.categories = x
                });
        },
        methods: {
            limpiarCampos(){
                this.name='',
                this.service='',
                this.address='',
                this.phoneNumber='',
                this.website='',
                this.isNeedPlatform=true,
                this.email='',
                this.email2='',
                this.password='',
                this.password2='',
                this.location='',
                this.category='Category ...',
                this.currency='USD',
                this.minPrice=0,
                this.maxPrice=0
            },
            crearUsuario(){
                var request={
                    name: this.name,
                    service: this.service,
                    address: this.address,
                    phoneNumber: this.phoneNumber,
                    website: this.website,
                    isNeedPlatform: this.isNeedPlatform,
                    email: this.email,
                    password: this.password,
                    location: this.location,
                    category: this.category,
                    currency: this.currency,
                    minPrice: this.minPrice,
                    maxPrice: this.maxPrice,
                };
                this.enviando=true;
                customerService.create(request).then(() => {
                    this.enviando=false;
                    Swal.fire(
              'Felicidades, ahora eres parte LetZTrav!',
              'Te enviaremos un correo para informarte sobre los siguientes pasos.',
              'success'
				);
                this.limpiarCampos();
                })
                .catch(error => {
                    console.log("desde aquiii");
                    console.log(error);
                    Swal.fire(
                        'Verifica tus datos.',
                        error.response.data.message||error.response.data.errors[0],
                        'warning'
                    );
                    this.enviando=false;
                    this.error = error;
                    this.loading = false;
                });
            },
        }
    }
  </script>
  
  <style>
      .title-container-options{
          text-align: left;
      }
      .title-box-option{
          color: #002fa5;
      }
      .optionsBox{
          text-align: left;
          margin-top: 15px;
          margin-bottom: 20px;
      }
      .label-checkbox{
          margin-left: 10px;
      }
      .title-form{
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
          color: gray;
      }
      .text-info-label{
        font-size: 14px;
      }
      .subtitle-form{
        font-size: 12px;
          font-weight: 600;
          color: gray;
          text-align: center;
      }
  </style>