<template>
    <div class="fondoprincipal">
      <div class="row banner-secondary">
        <div class="col-md-3 offset-md-4 col-sm-12 container-banner-info">
        <h2 class="title-principal-banner">Be part of our first customers.</h2>
        <h3 class="subtitle-principal-banner">Sing Up</h3>
        </div>
        <div class="col-md-2 ocultarpequeno">
          <img class="img-secondary-banner" alt="logo" src="../assets/phone2.png">
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-md-4 offset-md-4">
          <h2 class="title-principal-banner-blue">Customer sing up</h2>
          <RegisterCustomerForm :language="language" class="select-categories"></RegisterCustomerForm>
          <!--<button class="btn-send">
            Send / Enviar
          </button>-->
      </div>
  </div>
  </template>
  
  <script>
  import RegisterCustomerForm from '../components/firstCustomers/RegisterCustomerForm.vue';
  
  export default {
    props:['language'],
    components:{ RegisterCustomerForm },
    name:'FirstUsers'
  }
  </script>
  
  <style>
  
    .banner-secondary {
      background-color: #6bb9c5;
      height: 350px;
    }
  
    .container-banner-info{
      margin-top: 100px;
      text-align: left;
    }
  
    .img-secondary-banner{
      margin-top: 24px;
      height: 340px;
    }
  
    .select-categories{
      margin-bottom: 50px;
    }

    .fondoprincipal{
        background-color: #f6f7f9;
    }

    @media screen and (max-width: 770px){
      .container{
        grid-template-columns: 1fr;
      }
      
      /*.img{
        display: none;
      }*/

      .ocultarpequeno{
        display: none;
      }

      .login-content{
        justify-content: center;
      }
}
    
  </style>